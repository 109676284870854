<template>
  <footer>
      <div class="top-footer-bar">
      </div>
      <div class="bottom-footer-bar">
          <h5>JVDB &copy; - 2021</h5>
      </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  components: {
  },
};
</script>

<style scoped lang="scss">
.top-footer-bar {
  height: 8em;
  background-color: $blue;
}

.bottom-footer-bar {
  background-color: $dark-gray;
  color: white;

  & h5 {
    padding: 1em;
    margin: 0;
    font-weight: bold;
    font-size: .8em;
  }
}
</style>
