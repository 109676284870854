<template>
  <div class="d-flex flex-row justify-content-start align-items-center">
      <b-icon :icon="iconName"></b-icon>
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'InformationRow',
  props: {
    iconName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
span {
  font-weight: 500;
  margin: 0 1em;
}

svg {
  fill: $blue;
}

a {
  font-weight: 500;
  margin: 0 1em;

  color: black;
  text-decoration: none;
  text-align: left;
  &:visited {
    color: black;
    text-decoration: none;
  }
}

</style>
