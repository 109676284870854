<template>
  <div class="d-flex flex-row justify-content-center flex-wrap">
    <building-card
      v-for="building in buildingData"
      :key="building.id"
      :name="building.name"
      :address="building.address"
      :forRent="building.forRent"
      :imageURL="building.imageURL"
    />
  </div>
</template>

<script>
import BuildingCard from './BuildingCard.vue';
import { getAllBuildings } from '@/api/Building';
import { fromFirestore } from '@/models/converters/BuildingConverter';

export default {
  name: 'BuildingsList',
  components: {
    BuildingCard,
  },
  data() {
    return {
      buildingData: [],
    };
  },
  methods: {
    loadData: async () => {
      const snapshot = await getAllBuildings();

      const buildings = [];
      snapshot.forEach((doc) => {
        buildings.push(fromFirestore(doc, []));
      });
      return buildings;
    },
  },
  mounted() {
    this.buildingData = [];
    this.loadData()
      .then((buildings) => {
        this.buildingData = buildings;
      });
  },
};
</script>

<style scoped lang="scss">
</style>
