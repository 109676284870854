import { initializeApp } from 'firebase/app';
import { getFirestore, collection } from 'firebase/firestore/lite';
import { getAnalytics } from 'firebase/analytics';

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyCuLMY-b0IG4va4kH5RhglrLgNhFYwzIZA',
  authDomain: 'jvdb-538a4.firebaseapp.com',
  projectId: 'jvdb-538a4',
  storageBucket: 'jvdb-538a4.appspot.com',
  messagingSenderId: '40546887031',
  appId: '1:40546887031:web:3bf19a8f78ec68c13aad72',
  measurementId: 'G-FFNLRVRCFQ',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

if (process.env.JEST_WORKER_ID !== undefined) {
  console.log('Running a Jest Test mode');
  db.useEmulator('localhost', 9997);
}

// collection references
const buildingsCollection = collection(db, 'buildings');
export {
  db,
  buildingsCollection,
  analytics,
};
