import Building from '../Building';

// eslint-disable-next-line arrow-body-style
const toFirestore = (building) => {
  return {
    id: building.id,
    address: building.address,
    for_rent: building.forRent,
    image_url: building.imageURL,
    name: building.name,
  };
};

const fromFirestore = (doc, options) => {
  const data = doc.data(options);
  return new Building(
    doc.id,
    data.address,
    data.for_rent,
    data.image_url,
    data.name,
  );
};

export {
  toFirestore,
  fromFirestore,
};
