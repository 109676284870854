<template>
  <div style="padding: 8em 0;">
    <!-- <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
      exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. minim veniam,
      quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
    </p> -->

    <information-row iconName="geo-fill" class="my-2">
      <a href="https://www.google.com/maps/search/?api=1&query=junusstraat+5+wageningen" target="_blank">
        Junusstraat 5, 6701 AX, Wageningen
      </a>
    </information-row>

    <information-row iconName="telephone-fill" class="my-2">
      <a href="tel:+31317421000" target="_blank">(+31) 317 42 10 00</a>
    </information-row>

    <information-row iconName="envelope-fill" class="my-2">
      <a href="mailto:info@jvdb.nl" target="_blank">info@jvdb.nl</a>
    </information-row>
  </div>
</template>

<script>
import InformationRow from './InformationRow.vue';

export default {
  name: 'AboutUsInformation',
  components: {
    InformationRow,
  },
};
</script>

<style scoped lang="scss">
p {
  text-align: left;
}
</style>
