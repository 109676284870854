<template>
  <b-container class="d-flex flex-row justify-content-center">
    <b-row align-h="center" class="my-5 my-md-0 my-md-5">
      <b-col cols="12" md="6">
        <iframe
          class="map-box"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2458.257371480183!2d5.6624085!3d51.9657325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c7ab5eae1bc751%3A0x8120b48c3b64f17b!2sJunusstraat%205%2C%206701%20AX%20Wageningen!5e0!3m2!1snl!2snl!4v1629799044387!5m2!1snl!2snl"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          >
        </iframe>
      </b-col>
      <b-col cols="12" md="6"><about-us-information/></b-col>
    </b-row>
  </b-container>
</template>

<script>
import AboutUsInformation from './AboutUsInformation.vue';

export default {
  name: 'AboutUsBox',
  components: {
    AboutUsInformation,
  },
};
</script>

<style scoped lang="scss">
.map-box {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
</style>
