<template>
  <b-container>
    <b-row align-v="center">
      <b-col><hr/></b-col>
      <b-col cols="5" md="4"><h3>{{title}}</h3></b-col>
      <b-col><hr/></b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'SectionDivider',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
hr {
  border-top: 1px solid $dark-gray;
  opacity: 1;
}

</style>
