<template>
  <!-- <div class="">
      <h1>HELLO</h1>
  </div> -->
  <b-container class="mw-100">
    <b-row align-h="center">
        <b-col
          cols="10"
          md="7"
          lg="6"
          class="info-col d-flex flex-column flex-md-row justify-content-around"
        >
          <!-- flex-md-row justify-content-around -->
          <div>
            <h1>100</h1>
            <h2>Studentenwoningen</h2>
          </div>
          <div>
            <h1>12</h1>
            <h2>Winkelpanden</h2>
          </div>
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'HeroInformationOverlay',
};
</script>

<style scoped lang="scss">
.info-col {
  @include shadow(0px, 0px, 30px, 0px, rgba(black, 0.25));
  padding: 1em 0;
  background-color: white;
  border-radius: 5px;

  h1 {
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    color: $blue;

    @include sm-max {
      font-size: 3em;
    }

    @include md {
      font-size: 4em;
    }
  }

  h2 {
    color: $dark-gray;
    font-size: 1em;
  }
}
</style>
