<template>
  <div
    v-observe-visibility="{ callback: isViewableNow, once: true }"
    :class="{
      'visible animate__animated animate__backInUp': showAnimation,
      'invisible': !showAnimation
    }"
    class="building-card"
  >
    <a
      :href="`https://www.google.com/maps/search/?api=1&query=${address.replaceAll(' ', '+').replaceAll(',', '')}`"
      target="_blank"
    >
      <div class="image-container">
        <img
          class="card-image"
          :src="imageURL"
          :alt="`Afbeelding van ${name}`"
          loading="lazy"
        />

        <div
          class="to-rent-container d-flex flex-row justify-content-center align-items-center"
          v-if="forRent"
        >
          <h2>Te Huur!</h2>
        </div>
      </div>

      <div class="info-container d-flex flex-column justify-content-center">
        <span>
          {{name}}
        </span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'BuildingCard',
  props: {
    name: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
    forRent: {
      type: Boolean,
      required: true,
    },
    imageURL: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showAnimation: false,
    };
  },
  methods: {
    isViewableNow(isVisible) {
      setTimeout(() => { this.showAnimation = isVisible; }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
.building-card {
  @include shadow(0px, 0px, 30px, 0px, rgba(black, 0.25));
  width: 20em;
  height: 30em;
  margin: 2em;
}

a {
  text-decoration: none;
}

.image-container {
  position: relative;

  width: 100%;
  height: 85%;

  & img {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .to-rent-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & h2 {
      @include shadow(0px, 0px, 30px, 0px, rgba(white, 1));
      text-transform: uppercase;
      color: white;
      border: 8px solid white;
      padding: .45em 2em;
      background-color: rgba(white, 0.2);
      transform: rotateZ(-20deg);
    }
  }
}

.info-container {
  width: 100%;
  height: 15%;

  & span {
    color: $blue;
    font-size: 1em;
    font-weight: bold;
    text-align: left;
    padding-left: 1em;
  }
}
</style>
