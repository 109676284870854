<template>
  <div class="home d-flex flex-column justify-content-start align-items-center">
    <hero/>
    <div class="page-max-width">
      <section-divider title="Wie zijn Wij?"/>
      <about-us-box/>

      <section-divider title="Onze Panden"/>
      <buildings-list/>
    </div>

    <app-footer style="width: 100vw;"/>
  </div>
</template>

<script>
import 'animate.css';

import Hero from '@/components/Home/Hero/Hero.vue';
import SectionDivider from '@/components/Global/SectionDivider.vue';
import AboutUsBox from '@/components/Home/AboutUs/AboutUsBox.vue';
import BuildingsList from '@/components/Home/Buildings/BuildingsList.vue';
import AppFooter from '../components/Home/Footer/Footer.vue';

export default {
  name: 'Home',
  components: {
    Hero,
    SectionDivider,
    AboutUsBox,
    BuildingsList,
    AppFooter,
  },
};
</script>
