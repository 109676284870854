<template>
  <div class="hero-container">
      <img
        class="hero-image"
        src="@/assets/header-images/luchtfoto_wageningen.jpg"
        alt="Wageningen van Boven"
      />
      <div class="blue-gradient-overlay"></div>

      <div
        class="hero-text-box d-flex flex-column justify-content-center align-items-start"
      >
        <h1>Jansen van den Berg</h1>
        <h2>Investments</h2>
      </div>

      <div class="logo-text-box">
        <img
          class="logo-image"
          src="@/assets/header-images/header_logo.png"
          alt="JVDB Logo"
        />
      </div>

      <hero-information-overlay
        class="hero-info-overlay animate__animated animate__flipInX animate__delay-1s animate__slow"
      />
  </div>
</template>

<script>
import HeroInformationOverlay from '@/components/Home/Hero/HeroInformationOverlay.vue';

export default {
  name: 'Hero',
  components: {
    HeroInformationOverlay,
  },
};
</script>

<style scoped lang="scss">
.hero-container {
  width: 100vw;

  @include sm-max {
    height: 90vh;
  }

  @include md {
    height: 70vh;
  }

  position: relative;
  margin-bottom: 5em;
}

.hero-image {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blue-gradient-overlay {
  background: linear-gradient(140deg, rgba($blue,1) 0%, rgba($blue,0) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-text-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: white;

  @include sm-max {
    padding: 2em;
  }

  @include md {
    padding: 5em;
  }
}

.hero-info-overlay {
  position: absolute;
  bottom: -3em;
  left: 0;
}

.logo-text-box {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(white, 1);
  padding: 1.5em;

  border-radius: 0 0 0 20px;

  & .logo-image {
    @include sm-max {
      height: 30px;
    }
    @include md {
      height: 45px;
    }
  }
}
</style>
